<template>
  <table :class="$style.table">
    <thead>
      <tr :class="$style.row" @click="toggle">
        <td :class="$style.printhead" colspan="8">
          <div
            v-if="!!printer.docs.length"
            :class="[$style.expandButton, { [$style.expanded]: isExpanded }]"
          />
          <div :class="$style.head">
            Принтер:
            {{ printer.name }}
            <span :class="[$style.spanhead, $style.head]">
              - распечатанных документов
            </span>
            {{ printer.docs.length }}
          </div>
        </td>
      </tr>
    </thead>
    <tbody v-show="isExpanded">
      <tr
        v-for="(doc, ind) in sortedDocs"
        :key="doc.name + doc.printTime + ind"
        :class="$style.printbody"
      >
        <!-- eslint-disable vue/no-v-html -->
        <td
          v-for="(item, index) in assembleTableRow(doc)"
          :key="index"
          :style="{ width: columns[index].width }"
          v-html="item"
        />
        <!--eslint-enable-->
      </tr>
      <tr>
        <td colspan="2" :class="$style.hiddencell" />
        <td
          v-for="(count, index) in sumPages(printer.docs)"
          :key="index"
          :class="$style.total"
        >
          {{ count }}
        </td>
        <td colspan="2" :class="$style.hiddencell" />
      </tr>
    </tbody>
  </table>
</template>

<script>
import { formatDate, getTime } from '@/helpers/format-date';

export default {
  props: {
    printer: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isExpanded: true,
  }),
  computed: {
    sortedColumn() {
      const index = this.columns.findIndex((x) => x.sort > 0);
      const s = this.columns[index].sort;
      return { index, sort: s };
    },
    sortedDocs() {
      return this.printer.docs.slice(0).sort(this.compare);
    },
  },
  methods: {
    getColumnInfo(index) {
      const fields = [
        'username',
        'name',
        'pages',
        'copies',
        'pagesTotal',
        'cost',
        'printTime',
        'computerName',
      ];
      return fields[index];
    },
    compare(a, b) {
      const { index } = this.sortedColumn;
      const key = this.getColumnInfo(index);
      let a1 = a[key];
      let b1 = b[key];

      if ([0, 1, 7].includes(index)) {
        a1 = a1.toLowerCase();
        b1 = b1.toLowerCase();
      }

      if (this.sortedColumn.sort === 1) {
        if (a1 > b1) {
          return -1;
        }

        if (a1 < b1) {
          return 1;
        }
        return 0;
      }

      if (this.sortedColumn.sort === 2) {
        if (a1 < b1) {
          return -1;
        }

        if (a1 > b1) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    opened() {
      return !!this.openElements.find(
        (i) => i.id === this.id && i.type === this.type,
      );
    },
    getTime(date) {
      return getTime(date);
    },
    assembleTableRow(doc) {
      return [
        `<span style='font-weight:500'>${doc.username}</span>`,
        doc.name,
        doc.pages,
        doc.copies,
        doc.pagesTotal,
        doc.cost,
        `${formatDate(doc.printTime)} / `
          + `<span style='font-weight:500'>${this.getTime(doc.printTime)}</span>`,
        doc.computerName,
      ];
    },
    sumPages(docs) {
      let allpages = 0;
      let allcopies = 0;
      let alltotal = 0;
      let allcost = 0;
      docs.forEach((item) => {
        allpages += item.pages;
        allcopies += item.copies;
        alltotal += item.pagesTotal;
        allcost += item.cost;
      });
      return [allpages, allcopies, alltotal, allcost];
    },
  },
};
</script>

<style lang="scss" module>
.printhead {
  width: 100%;
  background: $light-gray;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  margin-top: 4px;
  height: 29px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $black;
  padding-top: 8px;
  padding-bottom: 7px;
  padding-left: 20px;
  z-index: 0;
  position: relative;
}

.printbody {
  width: 100%;
  background: $white;
  td {
    border: 1px solid #dfdfdf;
    font-family: inherit;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    height: 28px;
    color: $black;
    padding: 7px 7px 7px 20px;
    word-break: break-word;
  }
}

.spanhead {
  font-weight: 300;
  padding-left: 3px;
}

.hiddencell {
  border: 0px solid $white;
}

.total {
  background: $white;
  border: 1px solid #dfdfdf;
  font-family: inherit;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  height: 28px;
  color: $black;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
}

.bold {
  font-weight: 500;
}

.expandButton {
  flex-shrink: 0;
  margin-left: -27px;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 2;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}

.row {
  cursor: pointer;
  &:hover {
    background: $branchColor;
  }
}

.table {
  width: 100%;
  margin-bottom: 5px;
}

.head {
  display: inline-flex;
  padding-right: 3px;
}
</style>
