<template>
  <scrollable-container id="printable" :class="$style.scrollableContainer">
    <table :class="$style.header">
      <thead>
        <tr>
          <td v-for="column in columns" :key="column.name" :style="{ width: column.width }" @click="changeSort(column)">
            <div :class="$style.tdInline">
              {{ column.name }}
            </div>
            <img src="@/assets/images/icons/table/sort-arrow.svg" :class="[
              $style.tdInline,
              $style.arrow,
              {
                [$style.asc]: column.sort == 2,
                [$style.hidden]: column.sort == 0,
              },
            ]"
            >
          </td>
        </tr>
      </thead>
    </table>
    <container :class="$style.scrollableContent">
      <div v-if="isTableGroupedByDates">
        <div v-for="date in filteredtableData" :key="date.date">
          <div :class="$style.date">
            {{ formatDate(date.date) }}
          </div>
          <docs-table v-for="printer in date.printers" :key="printer.name" :printer="printer" :columns="columns" />
        </div>
      </div>
      <div v-else>
        <docs-table v-for="printer in filteredtableData" :key="printer.name" :printer="printer" :columns="columns" />
      </div>
    </container>
  </scrollable-container>
</template>

<script>
import { formatDate, getTime } from '@/helpers/format-date';
import { getPrintDocumentStatistics } from '@/api/methods/reports/printers/statistics/index';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import DocsTable from './docs-table.vue';

export default {
  components: {
    ScrollableContainer,
    Container,
    DocsTable,
  },
  data: () => ({
    name: 'Статистика печати по документам',
    columns: [
      { name: 'Пользователь', width: '15%', sort: 1, field: 'username' },
      { name: 'Имя документа', width: '15%', sort: 0, field: 'name' },
      { name: 'Кол-во страниц', width: '180px', sort: 0, field: 'pages' },
      { name: 'Кол-во копий', width: '160px', sort: 0, field: 'copies' },
      { name: 'Общее кол-во страниц', width: '240px', sort: 0, field: 'pagesTotal' },
      { name: 'Стоимость', width: '140px', sort: 0, field: 'cost' },
      { name: 'Дата / Время', width: '200px', sort: 0, field: 'printTime' },
      { name: 'Имя компьютера', width: '15%', sort: 0, field: 'computerName' },
    ],
    tableDataGroupedByDates: [],
    tableDataGroupedByPrinters: [],
  }),
  computed: {
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    isTableGroupedByDates() {
      return this.$store.getters['filters/isTableGroupedByDates'];
    },
    filteredtableData() {
      const selectedOption = this.$store.getters['filters/printerSelectedOption'];
      if (this.isTableGroupedByDates) {
        if (selectedOption) {
          const result = [];
          for (let i = 0; i < this.tableDataGroupedByDates.length; i++) {
            const printers = this.tableDataGroupedByDates[i].printers.filter(
              (printer) => printer.name.includes(selectedOption),
            );
            if (printers && printers.length) {
              result.push({
                date: this.tableDataGroupedByDates[i].date,
                printers,
              });
            }
          }
          return result;
        }
        return this.tableDataGroupedByDates;
      }
      if (selectedOption) {
        return this.tableDataGroupedByPrinters.filter((printer) => printer.name.includes(selectedOption));
      }
      return this.tableDataGroupedByPrinters;
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.fetch();
      }
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.tableDataGroupedByDates = [];
      this.tableDataGroupedByPrinters = [];
      if (this.dateRange.dateFrom && this.dateRange.dateTo) {
        this.promise = getPrintDocumentStatistics(
          this.dateRange.dateFrom,
          this.dateRange.dateTo,
        ).then(({ groupedByDates, groupedByPrinters }) => {
          this.tableDataGroupedByDates = groupedByDates;
          this.tableDataGroupedByPrinters = groupedByPrinters;

          this.sendSelectOptions();

          const exportArray = [];
          groupedByPrinters.forEach((printer) => {
            printer.docs.forEach((doc) => {
              const resObj = { Принтер: printer.name };
              for (let j = 0; j < this.columns.length; j++) {
                if (this.columns[j].field === 'printTime') {
                  resObj[this.columns[j].name] = `${formatDate(doc.printTime)}/${getTime(doc.printTime)}`;
                } else resObj[this.columns[j].name] = doc[this.columns[j].field];
              }
              exportArray.push(resObj);
            });
          });

          this.$store.commit('exportData/setDocStat', { name: this.name, data: exportArray });
          return { groupedByDates, groupedByPrinters };
        });
      }
    },
    sendSelectOptions() {
      const printerNameList = this.tableDataGroupedByDates.map((x) => x.printers.map((y) => y.name));
      this.$store.commit(
        'filters/setPrinterOptions',
        /* eslint-disable-next-line */
        [].concat.apply([], printerNameList)
      );
    },
    formatDate(date) {
      return formatDate(date);
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });
      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
  },
};
</script>

<style lang="scss" module>
.date {
  background-color: $light-gray;
  letter-spacing: -0.24px;
  width: 90px;
  height: 24px;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  padding: 5px 10px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  display: flex;
  align-items: center;
  color: #4b4b4b;
  margin-top: 15px;
  margin-bottom: 15px;
}

.header {
  border-collapse: collapse;
  margin: 10px 20px 0 20px;
  height: 31px;

  td {
    background: #dbf1ff;
    border: 1px solid $borderColor;
    padding: 6px 0;
    padding-left: 20px;
    word-break: break-all;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    align-items: center;
    letter-spacing: -0.08px;
    color: #232326;
    cursor: pointer;
    word-break: break-word;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.tdInline {
  display: inline-flex;
}

.arrow {
  float: right;
  margin-top: 7px;
  margin-right: 5px;

  &.asc {
    transform: rotate(180deg);
  }

  &.hidden {
    visibility: hidden;
  }
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
}

.scrollableContainer {
  padding-bottom: 20px;
}
</style>
